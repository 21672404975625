.minusplus-btngroup{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 1px;
    box-shadow: -5px -5px 4px 0px rgba(255, 255, 255, 0.3) inset;
    box-shadow: 5px 5px 4px 0px rgba(174, 174, 192, 0.2) inset;
    background: rgba(240, 240, 243, 1);
    border-radius: 2rem;
    button {
        color: black;
        padding: 0 10px;
        box-shadow: none;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 1rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset;
        border: 2px solid;
        border-image-source: linear-gradient(135deg, rgb(255 255 255 / 0%) 0%, rgba(255, 255, 255, 0.05) 100%);
        background: rgba(240, 240, 243, 1);
    }
    input{
        background: transparent;
        border: none;
        box-shadow: none;
        width: 42px;
        text-align: center;
        padding: 5px;
    }
    
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }
    //disable input style
    // input:disabled {
    //   background: #d2d2d2;
    // }
    button:disabled {
      background: #979797;
    }

}
.tabStep-indicator {
    li {
      flex: 1 1;
      text-align: center;
      color: #7D7D7D;
      .nav-link {
        width: -webkit-fill-available;
        margin: 0;
        border: 0;
        color: rgb(82, 82, 82);
        &:hover,
        &:active,
        &:focus {
          background: none;
          border-color:transparent;
        }
  
        &.active {
          background: none;
          border: 2px solid rgba(14, 116, 234, 1);
          border-radius: 50rem;
          height: 4rem;
          font-weight: 500;
        }
      }
    }
  }
  .copo-table {
    td:last-child{
      text-align: left;
    }
    th:last-child{
      text-align: left;
    }
  }

  // --------------- 
  .tab-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #99ceff;
    margin: 0px 4px;
  }
  
  .tab-circle.active {
    transform: scale(1.5);
    background-color: #108bf4;
  }
  .mob-copo-tabs{
    display: none;
  }
  .copo-input{
    width: 4rem;
    text-align: center;
  }
