.site-header-tab {
    // background: linear-gradient(0deg, rgba(233, 240, 246, 0.67), rgba(233, 240, 246, 0.67)), 
    // url("../../assets/images/header-bg.png"), repeat;
    background: url("../../assets/images/header-bg.png");
    background-size: contain;
    padding: 20px 0;
    ul {
        justify-content: center;
        align-items: center;
        text-align: center;
        .nav-item {
            margin-right: 40px;
            &:last-child {
                margin-right: 0;
            }
            a {
            border-radius: 8px;
            width: 124px;
            padding: 8px 16px;
            display: block;
            text-decoration: none;
            transition: transform .2s;
                &:link, &:visited {
                    background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), linear-gradient(131.67deg, #0E4575 4.72%, #1A81DC 98.79%);
                    background-blend-mode: soft-light, normal;
                    box-shadow: -5px -5px 10px #FAFBFF, 3px 4px 7px #858586;
                    color: #fff;
                }
                &:hover, &.active-tab {                    
                    background: linear-gradient(180deg, #333339 0%, #6D6D70 100%);
                    color: #fff;
                    transform: scale(1.15);
                }
                                
            }
            span {
                display: block;
                font-weight: 500;
                margin-top: 8px;
                line-height: normal;
                font-size: 14px;
            }
            img {
                max-height: 46px;
            }
        }
    }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    .site-header-tab {
        padding: 15px 0;
        ul {
            .nav-item {
                margin-right: 20px;
                a {
                width: 114px;
                padding: 8px;
                    &:hover, &.active-tab {
                        transform: scale(1.15);
                    }
                                    
                }
                span {
                    margin-top: 8px;                    
                }
                img {
                    max-height: 36px;
                }
            }
        }
    }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {    
    .site-header-tab {
        ul {
            .nav-item {
                margin-right: 15px;
                a {
                width: 90px;
                padding: 6px;
                    &:hover, &.active-tab {
                        transform: scale(1.05);
                    }
                                    
                }
                span {
                    margin-top: 6px;
                    font-size: 12px;
                }
                img {
                    max-height: 28px;
                }
            }
        }
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .site-header-tab {
        ul {
            .nav-item {
                margin-right: 10px;
                a {
                width: 80px;
                padding: 15px 6px;
                                    
                }
                span {
                    display: none;
                }
            }
        }
    }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .site-header-tab {
        ul {
            .nav-item {
                margin-top: 5px;
                margin-bottom: 5px;
                a {
                width: 80px;
                padding: 15px 6px;
                                    
                }
            }
        }
    }
}