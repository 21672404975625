.events-keys {
    box-shadow: none;
    .mitblock-body {
        margin-top: 1rem;
    }
    .event-keys-item {
        display: block;
        position: relative;
        padding-left: 2rem;
        margin-bottom: 0.75rem;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: 400;
        &.showall {
            font-weight: 500;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                border-color: #000;
            }
            &:checked ~ .checkmark:after {
                display: block;
            }
        }
        .checkmark {
            position: absolute;
            top: 1px;
            left: 0;
            height: 20px;
            width: 20px;
            border: 2px solid #ccc;
            border-radius: 3px;
            &:after {
                content: "";
                position: absolute;
                display: none;
            }
            &:after {
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid #000;
                border-width: 0 2px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}
.meet-with {
    box-shadow: 2px 5px 19px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0.5rem;
    .mitblock-body {
        display: flex;
        flex-direction: column;
    }
    .meet-people {
        display: flex;
        border: 2px solid #0C67C0;
        border-radius: 10px;
        padding: 5px 15px;
        margin-top: 10px;
        align-items: center;
        &:hover {
            border-color: #000;
            cursor: pointer;
            .fas {
                color: #0C67C0;
            }
        }
        img {
            width: 16px;
            margin-right: 5px;
        }
    }
}
.cal-events-modal {
    .start-date {
        background-color: rgba(17, 143, 246, 0.13);
    }
    .end-date {
        background-color: rgba(163, 163, 163, 0.2);
    }
    .start-date, .end-date {
        padding: 15px;
        border-radius: 15px;
        img {
            width: 16px;
            margin-right: 5px;
        }
    }
}
.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: rgba(49, 116, 173, 0.08);
    border: 2px solid #3174ad;
    color: #212529;
    padding: 4px 8px;
    font-size: 13px;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 1px auto #3174ad;
}
.rbc-month-view, .rbc-month-row + .rbc-month-row {
    border-color:#F1F1F1;
}
.rbc-date-cell {
    text-align: left;
    padding-left: 12px;
    padding-top: 5px;
    .rbc-button-link {
        font-weight: 500;
    }
    &.rbc-current {
        .rbc-button-link {
            background-color: #3174ad;
            color: #fff;
            border-radius: 50%;
            font-size: 13px;
            width: 24px;
            height: 24px;
            padding: 2px 2px 0px 1px;
        } 
    }
}
.rbc-month-view {
    box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
    border-radius: 20px;
    overflow: hidden;
    border-width: 2px;
}
.rbc-month-row + .rbc-month-row {
    border-top-width: 2px;
}
.rbc-header [role='columnheader'] {
    font-size: 15px;
    font-weight: 500;
    color: #777;
    display: inline-block;
}
.rbc-month-header .rbc-header [role='columnheader'] {
    padding: 12px 2px;
}
.rbc-header + .rbc-header {
    border-left: 2px solid #F1F1F1;
}
.rbc-time-slot {
    padding-top: 8px;
    font-size: 15px;
    font-weight: 500;
    color: #777;
}
.rbc-toolbar {
    margin-bottom: 20px;
    .rbc-toolbar-label {
        font-size: 18px;
        font-weight: 600;
    }
}
.rbc-row-segment {
    padding: 3px 10px;
}
.rbc-today {
    background-color: #fff;
}
.rbc-event-content {
    span {
        color: #fff;
        border-radius: 5px;
        font-size: 11px;
        padding: 3px 4px;
        vertical-align: middle;
        margin-right: 5px;
    }
}
.rbc-toolbar button {
    border: 2px solid #F5F6F7;
    padding: 0.5rem 1rem;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active, 
.rbc-toolbar button:focus, .rbc-toolbar button:hover,
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, 
.rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    background-color: #5F5F66;
    box-shadow: none;
    color: #fff;
    border-color: #5F5F66;
}
.rbc-day-slot .rbc-event-content {
    line-height: 20px;
}
.rbc-day-slot .rbc-event-label {
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
}
.rbc-time-header-cell .rbc-header {
    border-bottom: none;
    margin-top: 5px;
}
.rbc-time-view{
    border-radius: 16px;
    overflow: hidden;
}