.filter-btn{
    display: none;
}

// For Mobile View 
@media only screen and (max-width: 767.98px) {
    .FilterProgramDropdown-wrapper {
        display: none;
      }
    .FilterProgramDropdown-wrapper.hidden {
        display: block;
        transition: opacity 0.3s ease-in-out;
      }
    .filter-btn{
        background: #ffffff;
        display: block;
        border: 1.5px solid #b8b8b8;
        padding: 3px 10px;
        border-radius: 5px;
    }
  }
