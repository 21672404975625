.table-skeleton-wrapper {
  .table-skeleton {
    border-collapse: collapse; // Ensure the table structure doesn't show gaps as borders
  }

  .table-skeleton td {
    border: none; // Remove any borders on table cells
    padding: 0.5rem; // Adjust padding if needed
  }

  .table {
    border: none; // Ensure the base table class doesn't add borders
  }
}

.filter-skeleton-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .filter-skeleton {
    display: flex;
    gap: 1rem;

    .filter-item-skeleton {
      flex: 1;
    }

    .reset-button-skeleton {
      margin-left: auto; // Align the button to the right
    }
  }
}