// desktop footer style
.site-footer{
  background-size: cover;
  > ul {
    justify-content: center;
  }
}
// desktop footer style END


.md-stickyFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1200;
  border-top: 1px solid #e7e7e8;
  background: #ebf2fc;
  box-shadow: 0px -2px 7px 2px rgba(0, 0, 0, 0.07);
  padding: 12px 7px;
}

.md-footer-list {
  h2 {
    font-size: 14px;
    font-weight: 500;
    color: #626262;
    margin: 0;
    margin-top: 6px;
  }
}

.footerTitle.active {
  // background-color: #f5a0a0;
  color: #0866c7;
}

// sidebar style

.sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  .sidebar-content {
    background-color: #fff;
    width: 70%;
    padding: 20px;
    overflow-y: auto;
  }

  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      width: 22px;
    }
  }

  .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 20px 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-weight: 500;
      border-bottom: 2px solid #f2f2f2;
      padding-bottom: 12px;

      a {
        color: black;
        text-decoration: none;
      }
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}
