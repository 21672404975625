
@media only screen and (max-width: 767.98px) {
    .sitepage-title {
        font-size: 1.3rem;
        .btn {
            min-width: 90px;
            margin-left: 0.5rem;
        }
    }
  }
