.mailconfig-tabs{
    padding: 24px 16px;
    border-radius: 16px;
      div {
        width: 100%;
        margin-bottom: 8px;
        .nav-link{
            color: black;
            width: 100%;
        }
        .nav-link.active{
            background: none;
            border: 2px solid rgb(14, 116, 234);
            border-radius: 50rem;
            color: black;
            font-weight: 500;
        }
      }
}
