.homeLanding {
    .homeBox {
        background-color: #0B5DE0;
        box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.13);
        padding: 2rem 1rem;
        margin: 0.75rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        min-height: 218px;
        h4 {
            color: #fff;
            margin-top: 1.5rem;
            margin-bottom: 0;
        }
        img {
            max-width: 140px;
            max-height: 100px;
        }
        &:hover {
            background-color: #F98012;
        }
    }
    .colorBox1 {
        background-color: #125589;
    }
    .colorBox2 {
        background-color: #073AD1;
    }
    .colorBox3 {
        background-color: #002FBC;
    }
}