.site-header {
    --bs-gutter-x: 1.5rem;
    padding: 10px calc(var(--bs-gutter-x) * .5); border-bottom: 1px solid #E7E7E7;
    .site-logo {
        img {
            max-height: 68px;
        }
    }
    .sh-toolbar {
        li {
            border: 0.5px solid #E7E7E7;
            background-color: #F4F7FF;
            border-radius: 50%;
            width: 46px;
            height: 46px;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;
        }
        .sh-notification {
            position: relative;
            span {
                background: linear-gradient(180deg, #28C8E5 0%, #2C2C33 100%);
                position: absolute;
                top: -2px;
                right: 0;
                padding: 1px 5px;
                border-radius: 50%;
                font-size: 10px;
                color: #fff;
            }
        }
        img {
            width: 22px;
        }
    }
    .user-profile-pix {
        width: 46px;
        height: 46px;
        background-color: #F4F7FF;
        border: 0.5px solid #E7E7E7;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
    }    
}
.alert-popover.popover {
    background: #fff url('../../assets/images/header-bg.png');
    max-width: 380px;
    min-width: 320px;
}
.alert-popover-body.popover-body {
    padding: 0;
    overflow-y: auto;
    height: 380px;
}
.checkStatus {
    color: #fff !important;
    cursor: pointer;
}
.all-notifications {
    opacity: 1;
    visibility: visible;
    height: auto;
    overflow: hidden;
    .content-item-container {
        width: 100%;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        padding: 5px;
        position: relative;
        margin: 0;
        display: block;
        color: inherit;
        text-decoration: none;
        &.unread {
            background-color: #f4f4f4;
            .content-item-body .notification-message {
                font-weight: 600;
            }
        }
        .context-link {
            color: inherit;
            text-decoration: none;
        }
        .content-item-body {
            box-sizing: border-box;
            margin-bottom: 5px;
        }
        &.notification .content-item-body {
            .notification-image {
                display: inline-block;
                width: 24px;
                height: 24px;
                float: left;
                vertical-align: top;
                img {
                    height: 75%;
                }
            }
            .notification-message {
                display: inline-block;
                font-size: 12px;
                width: calc(100% - 24px);
            }
        }
        .content-item-footer {
            text-align: left;
            box-sizing: border-box;
            .timestamp {
                font-size: 10px;
                line-height: 10px;
                margin: 0;
                color: inherit;
                margin-left: 24px;
            }
        }
        .view-more {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 12px;
            line-height: 12px;
        }
    }
}
.seeAlert {
    text-align: center;
    border-top: 1px solid #ddd;
    display: grid;
}

@media (max-width: 575.98px) {
    
}

@media (min-width: 576px) and (max-width: 767.98px) {
    
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}
