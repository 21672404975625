.sitepage-title {
    // border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    font-size: 1.64rem;
    span {
        color: #1B609D;
    }
}