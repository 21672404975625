// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .homeLanding {
        .homeBox {
            min-height: 140px;
            padding: 1rem;
            img {
                max-width: 80px;
                max-height: 60px;
            }
            h4 {
                font-size: 18px;
                margin-top: 1rem;
            }
        }
    }
}