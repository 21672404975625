.pricerequest-wrapper{
    .modal-dialog{
      max-width: 38rem;
    }
    .modal-header{
      padding-top: 19px;
      border: none;
    }
    .modal-body{
      padding-top: 0;
      padding-bottom: 3rem;
      padding-top: 1rem;
    }
    div{
      // width: 40rem;
      .requestmodal-content{
        h4{
          font-size: 30px;
          font-weight: 600;
        }
        div a {
          color: black;
          font-size: 18px;
          z-index: 4;
        &:hover{
          color: #1B609D;
        }
        }
      }
    }
    .modal-content {
      background: white url(../../assets/images/landing-bg.png);
      border: none;
      animation: flipInY 0.6s ease-in-out;
      padding: 0rem 1rem;
    }
    .wavebg{
      width: 55%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  
  
  @keyframes flipInY {
    from {
      transform: rotateY(90deg);
      opacity: 0;
    }
    to {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
  
  // --- for mobile --- 
  @media only screen and (max-width: 767.98px) {
  .pricerequest-wrapper{
    .modal-header{
      padding-right: 4px;
    }
    .modal-body{
     div{
      flex-direction: column-reverse;
     }
    }
  }
  .request-image{
    text-align: center;
  }
  }