.landing-wrapper {
  background-image: url("../../assets/images/landing-bg.png");
  // height: 90vh;
}

.landing-header{
  padding:0.35rem 2rem;
  // height: 15vh;
  width: 100%;
  .search-expand{
    display: flex;
    background: #F4F7FF;
    box-shadow: 1px 2px 9px #bcbcbc80;
    input {
      box-shadow: none;
      background: #ffffff00;
      border: none;
    }
  }
  .logo-wrapper{
    img{
      height: 100px;
    }
  }
  .search-btn{
    height: 2.8rem;
    width: 2.8rem;
    img{
      height: 1rem;
      width: 1rem;
    }
  }
}

.login-btn {
    position: absolute;
    top: 50%; 
    // left: 1%;
  Button{
    background: linear-gradient(90deg, #13568A -0.02%, #076BD0 100%);
    color: white;
    font-size: 1.3rem;
  }
  Button:hover{
    background: linear-gradient(90deg, #0e456e -0.02%, #034c95 100%);
    color: white;
  }
  .signup{
    background: white;
    border: 2px solid #2778c4;
    color: #1b609d;
  }
}
.footer-links{
  background-image: url("../../assets/images/landing-linksbg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 20px;
  height: 10vh;
  .row {
    div {
      border-right: 2px solid orange;
    }
    div:last-child {
      border-right: none;
    }
  }
}

.landing-content {
  // height: 80.2vh;
  width: 100%;
  position: relative;
  // background: url("../../assets/images/landingImage.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .bgbtn-wrapper{
    max-width: 98%;
    margin: 0 auto;
  }
  .laptopbg-mobile{
    display: none;
  }
  .demovideo-wrapper {
    position: absolute;
    top: 26vh;
    right: 5.2vw;
    iframe {
      width: 49vw;
      height: 48vh;
    }
  }
}
.butext{
  display: none;
}

.landing-page{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .landing-wrapper{
    flex: 1;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .footer-links{
    margin-top: auto;
  }
}



// -----Couses Section-------- 
.programlist-wrapper{
height: 96vh;
}
.landing-courses{
  background: url(../../assets/images/ligh-wave-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0rem;
  justify-content: space-between;
  .title-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 3rem;
    width: 35%;
    h4{
      font-size: 32px;
      margin-bottom: 10px;
    }
    div{
      font-size: 18px;
    }
    .explorer-btn{
      background: linear-gradient(90deg, #13568A -0.02%, #076BD0 100%);
      color: white;
      font-size: 1.3rem;
      width: max-content;
      border: none;
      padding: 7px;
      margin-top: 17px;
    }
  }
  .courseswrapper{
    background: url(../../assets/images/dark-wave-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem;
    display: flex;
    gap: 2rem;
    height: max-content;
    flex: 1;
    justify-content: space-around;
    .landingprograms{
      flex: 1;
    }
    .course-container{
      // display: flex;
    .course-image{
      background: white;
      height: 300px;
      overflow: hidden;
      border-radius: 1rem;
      position: relative;
      text-align: center;
      > img{
        height: -webkit-fill-available;
        object-fit: cover;
        height: inherit;
      }
      .course-keypoints{
        position: absolute;
        gap: 0px 16px;
        top: 0;
        background: #000000bf;
        width: 100%;
        height: 100%;
        color: white;
        border-radius: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 14px;
        padding: 16px;
        justify-items: start;
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
        span{
          text-align: left;
        }
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
      &:hover {
        .course-keypoints {
          opacity: 1;
          transform: translateY(0);
        
        }
      }
    }
    .course-title{
      text-align: center;
      color: white;
      margin-top: 1rem;
      font-size: 20px;
    }
    }
    .allcourses-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    button {
      background: white;
      border: 2px solid #2778c4;
      color: #1b609d;
      padding: 12px 0px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      font-weight: 500;
      display: none;
    }
  } 
  }
}

.landing-skeleton{
  gap: 1rem;
  div{
    flex: 1;
    div{
      width: 100%;
      border-radius: 1rem;
    }
  }
}

// ---------------- Program List -------------
.program-catalogue { 
  background: none;
  padding: 0;
  .programlist{
    background: none;
    padding-top: 0;
    > div{
      gap: 24px 0px;
      width: 100%;
      justify-content: center;
    .course-container {
      .course-title{
        color: black;
        font-weight: 500;
      }
      .course-image 
      {
        border: 2px solid #0d60af;
        > img{
          width: 100%;
        }
      }
    }
    }
  }
}
.programcataloguepage{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .programlist-wrapper{
    height: auto;
    flex: 1;
    .logo-wrapper{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.programheader-login{
  position: unset;
}


// ---------------- Program Summary -------------

.programlist-wrapper{
  .program-summary{
    // height:15vh;
    padding: 0;
  }
}

.bgcourse-right{
  position: absolute;
    right: 0;
    z-index: -1;
    top: 60%;
}

// ---------PROGRAM LIST FILTERS--------------------

.programlist-filters{
  background: #b5cff68c;
  border-radius: 1rem;
  padding: 1rem;
  .programmultiselect{
    > div {
      padding: 7px 15px;
      border-radius: 6px;
      border: 0.5px solid rgba(255, 255, 255, 0.8);
      background: #FFF;
      box-shadow: 2.5px 2.5px 5px 0px #CFD0D2 inset, -2.5px -2.5px 5px 0px #EBEBEB inset;
    span{
      background: linear-gradient(90deg, #13568A -0.02%, #076BD0 100%);
      color: white;
    }
      input{
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
    }
  }
  .highlightOption {
    background: linear-gradient(90deg, #13568A -0.02%, #076BD0 100%);
}
}
.catalogfilter-header{
  > button{
    padding: 0;
    background: none;
    &:focus{
      box-shadow: none;
    }
  }
  .accordion-button:not(.collapsed){
    background: none;
    color: black;
    border-bottom: 1px solid #92929261;
    margin-bottom: 16px;
    padding-bottom: 6px;
    &::after{
      display: none;
    }
  }
}
.programlist-filters .accordion, .programlist-filters .accordion .accordion-item{
  background: none;
}
// ---------PROGRAM LIST FILTERS end--------------------

