.site-footer {
    background-image: url("../../assets/images/footer-bg.svg");
    background-repeat: repeat;
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;    
    .list-group-item {
        border: none;
        background: none;
        color: #fff;
        font-size: 12px;
    }
}
// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .site-footer {
        .list-group-horizontal {
            flex-wrap: wrap;
        }
    }
}
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    
}